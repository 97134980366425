<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="国土地块名称" prop="villageName" style="margin-bottom: 0px">
                <el-input v-model="form.villageName" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="性质">
                <el-select v-model="form.belong" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.belong.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="清扫保洁面积(m²)" prop="cleanArea">
                <el-input v-model="form.cleanArea" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="人口数量" prop="population">
                <el-input v-model="form.population" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="非居住建筑数量(栋)" prop="unLiveNum">
                <el-input v-model="form.unLiveNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="村内总户数(户)" prop="familyNum">
                <el-input v-model="form.familyNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="居住建筑数量(栋)" prop="liveNum">
                <el-input v-model="form.liveNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="居住建筑平均数(层)" prop="liveAverageNum">
                <el-input v-model="form.liveAverageNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="范围">
                <el-input v-model="form.villageScope" placeholder="请输入" maxlength="254"></el-input>
              </el-form-item>
              <el-form-item label="地址" style="width:100%">
                <el-input v-model="form.villageAddress" placeholder="请输入" maxlength="254" style="width:300px"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <ayl-map-polygon v-model="mapData"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/mixins/add";
export default {
  mixins: [add],

  data() {
    return {
      nav: [{ name: "新增国土地块" }],
      mapData: {
        //地图数据
        polygonScope: null, //经纬度，数组
        scope: null //标段范围，经纬度点阵数组
      },
      form: {
        villageName: null, //国土地块名称
        bidId: null, //关联标段编号
        belong: null, //性质 0-村、1-居委会
        cleanArea: null, //清扫保洁面积
        population: null, //人口数量
        unLiveNum: null, //非居住建筑数量
        familyNum: null, //村内总户数
        liveNum: null, //居住建筑数量
        liveAverageNum: null, //居住建筑平均数
        villageAddress: null, //地址
        villageScope: null, //范围
        villageCoordinateScope: null //位置坐标
      },
      rules: {
        villageName: [
          {
            required: true,
            message: "请输入1~20位国土地块名称",
            min: 1,
            max: 20,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: [
          {
            required: true,
            message: "请选择所属标段",
            trigger: "blur"
          }
        ],
        cleanArea:{trigger: "blur",validator: this.$validator.isNumber},
        population:{trigger: "blur",validator: this.$validator.positiveInteger},
        unLiveNum:{trigger: "blur",validator: this.$validator.positiveInteger},
        familyNum:{trigger: "blur",validator: this.$validator.positiveInteger},
        liveNum:{trigger: "blur",validator: this.$validator.positiveInteger},
        liveAverageNum:{trigger: "blur",validator: this.$validator.positiveInteger},
      }
    };
  },
  methods: {
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      await self.$api_hw.plotManagement_insertPlot({
        sanitationVillagePlotVO: _fixData(self.form)
      });

      //修剪form的数据
      function _fixData(d) {
        //补字段
        if(self.mapData){
          if(self.mapData.polygonScope)
            d["villageCoordinateScope"] = JSON.stringify(self.mapData.polygonScope);
          else
            d["villageCoordinateScope"] = "";
          d["isDrawn"]=self.mapData.polygonScope?1:0;
        }
        self.log(d);
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .tips
    width: 407px
    height: 22px
    margin-left: 170px
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(255,140,26,1)
    line-height: 32px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

</style>
